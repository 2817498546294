<template>
    <div class="asset-maintenance-policies">
        <ListHeader class="u-bb">
            <template slot="title">{{ $t('title') }}</template>

            <template slot="accessory">
                <div class="l-inline l-gap-2">
                    <router-link
                        :to="{
                            name: 'dashboardMaintenanceHistory',
                            query: {
                                asset,
                            },
                        }"
                    >
                        <IconButton no-button>
                            <MenuIcon width="18" height="18" />
                        </IconButton>
                    </router-link>

                    <IconButton :title="$t('addHint')" @click="handleAdd()">
                        <AddIcon width="18" height="18" />
                    </IconButton>
                </div>
            </template>
        </ListHeader>

        <List :is-loading="isLoading">
            <ListItem v-for="(assignment, i) in assignments" :key="i">
                <router-link
                    :to="{
                        name: 'dashboardMaintenanceHistory',
                        query: {
                            asset: assignment.asset,
                            policy: assignment.policy,
                        },
                    }"
                    class="l-full-width"
                >
                    <div class="l-inline l-gap-2 l-center-v">
                        <TaskListCheckIcon
                            :class="assignment.due_in ? 'active' : 'inactive'"
                            width="24"
                            height="24"
                        />

                        <div class="l-grow-1 l-stack l-gap-1">
                            <p>{{ assignment.policy_name }}</p>
                            <p class="t-small t-subtle">
                                {{ assignmentStates[i] }}
                            </p>
                        </div>

                        <IconButton
                            :title="$t('addHint')"
                            @click.prevent="handleAdd(assignment.policy)"
                        >
                            <AddIcon width="18" height="18" />
                        </IconButton>
                    </div>
                </router-link>
            </ListItem>
        </List>
    </div>
</template>

<script>
import { httpHelper, measurementHelper } from '@/utils'
import AddIcon from '@/components/icons/AddIcon'
import IconButton from '@/components/IconButton'
import List from '@/components/List'
import ListHeader from '@/components/ListHeader'
import ListItem from '@/components/ListItem'
import MaintenanceFormModal from '@/components/redesigned/MaintenanceFormModal'
import MenuIcon from '@/components/icons/MenuIcon'
import TaskListCheckIcon from '@/components/icons/TaskListCheckIcon'

export default {
    name: 'AssetMaintenancePolicies',
    components: {
        AddIcon,
        IconButton,
        List,
        ListHeader,
        ListItem,
        MenuIcon,
        TaskListCheckIcon,
    },
    props: {
        asset: {
            type: [Number, String],
            required: true,
        },
    },
    data() {
        return {
            assignments: [],
            converters: {
                duration: value => Math.round(value / 60 / 60 / 24),
                odometer: measurementHelper.converters.odometer,
                running_time: value => Math.round(value / 60 / 60),
            },
            isLoading: false,
            units: {
                duration: count => this.$tc('day', count),
                odometer: () => measurementHelper.units.odometer,
                running_time: count => this.$tc('hour', count),
            },
        }
    },
    computed: {
        assignmentStates() {
            return this.assignments.map(({ due_in, policy_type }) => {
                let value =
                    policy_type in this.converters
                        ? this.converters[policy_type](due_in)
                        : due_in

                if (policy_type in this.units) {
                    value = `${value} ${this.units[policy_type](value)}`
                }

                return this.$t('dueIn', [value])
            })
        },
    },
    watch: {
        asset() {
            this.fetchAssignments()
        },
    },
    mounted() {
        this.fetchAssignments()
    },
    methods: {
        async fetchAssignments() {
            this.isLoading = true
            const assignments = []

            let url =
                'maintenance-policy-assignments/' +
                `?limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}` +
                `&asset=${this.asset}`

            while (url) {
                const { data } = await httpHelper.get(url)
                assignments.push(...data.results)
                url = data.next
            }

            this.assignments = assignments
            this.isLoading = false
        },
        handleAdd(policyId) {
            this.$modal.show(
                MaintenanceFormModal,
                {
                    assetId: this.asset,
                    policyId,
                    onSubmit: this.fetchAssignments,
                },
                { width: 600, height: '75%' }
            )
        },
    },
}
</script>

<i18n>
{
    "en": {
        "addHint": "Add new record",
        "day": "day | days",
        "dueIn": "{0} left",
        "hour": "hour | hours",
        "title": "Maintenance policies"
    },
    "de": {
        "addHint": "Neuen Datensatz hinzufügen",
        "day": "Tag | Tage",
        "dueIn": "{0} übrig",
        "hour": "Stunde | Stunden",
        "title": "Wartungspläne"
    },
    "fr": {
        "addHint": "Ajouter un nouvel enregistrement",
        "day": "jour | jours",
        "dueIn": "{0} restants",
        "hour": "heure | heures",
        "title": "Politiques de maintenance"
    },
    "it": {
        "addHint": "Aggiungere un nuovo record",
        "day": "giorno | giorni",
        "dueIn": "{0} a sinistra",
        "hour": "ora | ore",
        "title": "Politiche di manutenzione"
    }
}
</i18n>

<style lang="scss">
.asset-maintenance-policies {
    .active {
        .b {
            stroke: $color-green;
        }
    }

    .inactive {
        .b {
            stroke: $color-red;
        }
    }
}
</style>
